@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-face {*/
/*  font-family: "Noto Sans";*/
/*  font-style: normal;*/
/*  font-weight: 100;*/
/*  src: url("./assets/fonts/AnyConv.com__NotoSans-Regular.woff") format("woff");*/
/*}*/
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  /*font-weight: normal;*/
  src: url("./assets/fonts/NotoSans-Regular.ttf") format("truetype");
}
/*@font-face {*/
/*  font-family: "Noto Sans";*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src: url("./assets/fonts/NotoSans-Regular.ttf") format("truetype");*/
/*}*/
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/NotoSans-Bold.ttf") format("truetype");
}

@layer base{
  html {
    font-family: "Noto Sans", serif;
  }
}
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: "Noto Sans", serif;
  /*src: url("./assets/fonts/AnyConv.com__NotoSans-Regular.woff") format("woff");*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Noto Sans", serif
}
